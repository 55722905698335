/**
 * Middleware to redirect to the first document of an API if no document is specified in the route.
 */
export default defineNuxtRouteMiddleware(async (to) => {
  const { $portalApi, $i18n } = useNuxtApp()

  // If the user attempts to navigate to a /docs page with no document_slug, redirect to the first document of the API.
  // @ts-ignore - route params exist
  if (to.params.api_slug && to.path.includes('/docs') && !to.params.document_slug?.length) {
    try {
      // If the route is a document route and no document slug is provided, fetch the first document for the API and redirect to it.
      const { data: documents } = await $portalApi('/api/v3/apis/{apiIdOrSlug}/documents', {
        path: {
          apiIdOrSlug: computed(() => {
            // @ts-expect-error - to.params.api_slug exists
            return String(to.params.api_slug || '')
          }).value,
        },
        headers: { accept: 'application/vnd.konnect.document-tree+json' },
      })

      if (documents?.length && documents?.[0]?.slug) {
        return navigateTo({
          name: 'apis-api_slug-docs-document_slug',
          params: {
            // @ts-expect-error - route param exists
            api_slug: to.params.api_slug,
            document_slug: [documents[0].slug],
          },
        }, {
          redirectCode: 302,
        })
      // @ts-expect-error - to.params.api_slug exists
      } else if (to.params.api_slug) {
        // Else fetch the API and attempt to navigate to the spec document
        const apiData = await $portalApi('/api/v3/apis/{apiIdOrSlug}', {
          path: {
            apiIdOrSlug: computed(() => {
              // @ts-expect-error - to.params.api_slug exists
              return String(to.params.api_slug || '')
            }).value,
          },
        })

        const specId = apiData?.specifications?.[0]?.id

        // Attempt to navigate to the first spec document
        if (specId) {
          return navigateTo({
            name: 'apis-api_slug-specifications-spec_id-spec_path',
            params: {
            // @ts-expect-error - route param exists
              api_slug: to.params.api_slug,
              spec_id: specId,
            },
          }, {
            redirectCode: 302,
          })
        }
      }
      // Any scenario where no documents are found from this point are handled in `layers/core/app/pages/apis/[api_slug]/docs/index.vue`
    } catch {
      throw createError({
        statusCode: 404,
        statusMessage: $i18n.t('errors.pages.not_found'),
        fatal: true,
      })
    }
  }
})
